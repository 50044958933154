import { Text } from "@instructure/ui-text";
import { View } from "@instructure/ui-view";
import { FC } from "react";
import { Loader } from "../../../canvasApp/components/UI/Loader/Loader";
import { Loading } from "../../LoadingSpinner/Loading";

interface KPIChartProps {
  loading: boolean;
  value: number;
  kpiName: string;
  height: any;
  width: any;
}

const SingleMetricChart: FC<KPIChartProps> = ({ loading, value, kpiName, height, width }) => {
  return (
    <View
      as="div"
      margin="small"
      padding="medium"
      display="flex"
      textAlign="center"
      height={height}
      width={width}
      borderRadius="medium"
      shadow="resting"
    >
      <div
        style={{
          flex: 1,
          textAlign: "center",
          alignContent: "center",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            {" "}
            <Text as="div" size="xx-large" weight="bold">
              {typeof value === "number" ? value?.toFixed(2) : value}
            </Text>
            <Text as="div" size="medium">
              {kpiName}
            </Text>
          </>
        )}
      </div>
    </View>
  );
};

export default SingleMetricChart;
